import * as React from 'react'
import { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { withUnpublishedPreview } from 'gatsby-source-prismic'

import { NotFoundPageQuery } from '../types.generated'
import {
  PageTemplate,
} from '../templates/page'
import { useSiteSettings } from '../hooks/useSiteSettings'

import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import PageBodyHeader from '../slices/PageBodyHeader'
import PageBodyFooter from '../slices/PageBodyFooter'

import banana from '../assets/404-image.png'

/**
 * Mapping of Prismic custom type API IDs to their templates. Add mappings here
 * as custom types and templates are created.
 *
 * @see https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews.md#withUnpublishedPreview
 */
const customTypeToTemplate = {
  page: PageTemplate,
}

export const NotFoundPage = ({
  // data,
  // location,
}: PageProps<NotFoundPageQuery>) => {
  const siteSettings = useSiteSettings()
  // const page = data?.prismicPage

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  // const meta = React.useMemo(
  //   () => ({
  //     rootData: data,
  //     location,
  //   }),
  //   [data, location],
  // )

  return (
    <Layout>
      <Helmet>
        <title>
          {'Page not found'}{' '}
          | {siteSettings.siteName}
        </title>
        {/* {page?.data?.meta_description && (
          <meta name="description" content={page?.data?.meta_description} />
        )} */}
      </Helmet>

      <PageBodyHeader />
        <div className="error-404">
          <h1>404</h1>

          <img src={banana} alt="panana peel" />

          <p>
            Uh Oh! It looks like that page you’re looking for does not exist. Return home or try searching instead.
          </p>

          <Link className="button" href="/">
            Go to Home
          </Link>
        </div>
      {/* <MapSlicesToComponents
        list={page?.data?.body}
        map={slicesMap}
        meta={meta}
        listMiddleware={slicesMiddleware}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      /> */}
      <PageBodyFooter />
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: customTypeToTemplate,
})

// export const query = graphql`
//   query NotFoundPage {
//     prismicPage(uid: { eq: "404" }) {
//       _previewable
//       data {
//         title {
//           text
//         }
//         meta_title
//         meta_description
//         body {
//           __typename
//           ... on Node {
//             id
//           }
//           ...SlicesPageBody
//         }
//       }
//     }
//   }
// `
